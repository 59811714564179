import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
import commonModalOracle from '../../commonModalOracle';
import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'addProvisions',
  components: {
    appStrings,
    DatePicker,
    commonModalOracle
  },
  watch: {
    currentPage: function () {
      this.getProvisonDtlById();
    },
    perPage: function () {
      this.currentPage = 1;
      this.getProvisonDtlById();
    }
  },
  props: ['provRowDetails', 'wfProvisionDetails'],
  data() {
    return {
      totalRows: null,
      currentPage: 1,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      loader: false,
      editMode: false,
      showAlert: false,
      responseMsg: '',
      isSuccess: false,
      defaultValue: {
        value: null,
        text: null
      },
      addProvData: [],
      addProvFields: [
        {
          key: 'add',
          stickyColumn: true
        },
        {
          key: 'remove',
          stickyColumn: true
        },
        {
          key: 'po_type',
          label: 'Provision Type'
        },
        // {
        //   key: 'amount'
        // },
        {
          key: 'vendor_name',
          label: 'Vendor'
        },
        {
          key: 'vendor_code',
        },
        {
          key: 'po_number',
          label: 'PO Number'
        },
        {
          key: 'release_num',
          label: 'PO Release No.'
        },
        {
          key: 'po_currency',
          label: 'PO Currency'
        },
        {
          key: 'po_line_num',
          label: 'PO Line Number'
        },
        {
          key: 'item_description'
        },
        {
          key: 'service_user'
        },
        {
          key: 'user_department'
        },
        {
          key: 'service_date'
        },
        // {
        //   key: 'concatenated_segments',
        //   label: 'Account Code'
        // },
        {
          key: 'company'
        },
        {
          key: 'lob'
        },
        {
          key: 'department'
        },
        {
          key: 'state_code'
        },
        {
          key: 'cost_center'
        },
        {
          key: 'account_num'
        },
        {
          key: 'sub_account_num'
        },
        {
          key: 'account_desc'
        },
        {
          key: 'prov_acc_num',
          label: 'Provision Account'
        },
        {
          key: 'actualAmt',
          label: 'Amount'
        },
        {
          key: 'debit'
        },
        // {
        //   key: 'credit'
        // },
        // {
        //   key: 'exchange_rate'
        // },
        // {
        //   key: 'accounting_date'
        // },
        {
          key: 'journal_name'
        },
        {
          key: 'journal_description'
        },
        {
          key: 'working_days',
          label: 'MIS-1'
        },
        {
          key: 'extra_days',
          label: 'MIS-2'
        },
        {
          key: 'leave_encashment',
          label: 'MIS-3'
        },
        {
          key: 'mis_4',
          label: 'MIS-4'
        },
        {
          key: 'mis_5',
          label: 'MIS-5'
        },
        // {
        //   key: 'expense_month'
        // }
      ],
      legalEntity: {
        text: null,
        value: null
      },
      department: {
        text: null,
        value: null
      },
      provHdrId: 0,
      approvalStatus: 'DRAFT',
      showValueSetModal: false,
      parent_value_set_id: null,
      period: null,
      batchName: null,
      currency: null,
      rowCount: null,
      options: [
        'With PO',
        'Without PO',
        'Without Vendor'
        // { value: 'With PO', text: 'With PO' },
        // { value: 'Without PO', text: 'Without PO' }
      ],
      modalType: null,
      showCommonModal: false,
      specIndex: null,
      segmentIndex: null,
      vendorCode: null,
      poNumber: null,
      poLineNum: null,
      stateCode: null,
      legalEntityValue: null,
      accountNum: null,
      expenseMonth: null,
      totalDebit: null,
      totalCredit: null,
      ouName: null,
      requestName: null,
      respName: null,
      leIdRequest1: null,
      pathRequest2: null,
      hdrIdRequest3: null,
      successMsg: null,
      showApproveRejectModal: false,
      approvRejectType: null,
      approvalReason: null,
      rejectedReason: null,
      approvalRemarks: null,
      rejectionRemarks: null,
      urlSummaryData: null,
      wfApprovalStatus: null,
      approvalStatusKey: null,
      oraclePostingStatus: null,
      actualAmt: null,
      realAmt: null
    };
  },
  mounted() {
    if (this.provRowDetails) {
      // from grid
      this.provHdrId = this.provRowDetails.prov_hdr_id;
      this.getProvisonHdrById();
    }
    else {
      this.editMode = true;
    }
    if (this.wfProvisionDetails) {
      // from dashboard
      this.provHdrId = this.wfProvisionDetails.prov_hdr_id;
      this.getProvisonHdrById();
    }
    if (this.$route.params.summaryData) {
      this.urlSummaryData = JSON.parse(
        Buffer.from(this.$route.params.summaryData, 'base64').toString('ascii')
      );
    }
    if (this.urlSummaryData) {
      // from link
      this.provHdrId = this.urlSummaryData.prov_hdr_id;
      this.getProvisonHdrById();
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save') {
          if (this.approvalStatus === 'DRAFT' || this.approvalStatus === 'REJECTED') {
            this.addEditProvisionDetails();
          }
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    getProvisonHdrById() {
      const payload = {
        prov_hdr_id: this.provHdrId,
        requestPayload: {
          userId: this.$store.state.auth.userId
        }
      }
      this.loader = true;
      this.$store
        .dispatch('provisionsAdmin/getProvisonHdrById', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.page[0];
            this.wfApprovalStatus = result.approval_status_key;
            this.approvalStatusKey = result.approval_status_key;
            this.oraclePostingStatus = result.oracle_posting_status;
            this.totalCredit = result.credit;
            this.totalDebit = result.debit;
            if (result) {
              this.setFielsForProvision(result);
            }
            this.getProvisonDtlById();
            if (this.approvalStatus === 'APPROVED' && !this.oraclePostingStatus) {
              this.getApprovedBatch();
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    setFielsForProvision(item) {
      this.legalEntity.text = item.le_name;
      this.legalEntity.value = item.erp_org_id;
      this.currency = item.currency;
      this.period = item.provision_period;
      this.batchName = item.batch_name;
      this.approvalStatus = item.approval_status;
      this.department.text = item.department_meaning;
      this.department.value = item.department;
      this.addProvData = item.po_type_details;
      this.rowCount = item.line_count;
    },
    getProvisonDtlById() {
      const payload = {
        prov_hdr_id: this.provHdrId,
        requestPayload: {
          _page: this.currentPage - 1,
          _limit: this.perPage,
        }
      }
      this.loader = true;
      this.$store
        .dispatch('provisionsAdmin/getProvisonDtlById', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.addProvData = resp.data.data.page;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    setFieldsForDtl(item) {
      this.legalEntity.text = item.le_name;
      this.legalEntity.value = item.le_id;
      this.currency = item.currency;
      this.period = item.provision_period;
      this.batchName = item.batch_name;
      this.approvalStatus = item.approval_status;
      this.addProvData = item.po_type_details;
    },
    qtyTwoDecimalValue(item, index) {
      if(item.po_type === 'With PO'){
      if (item.actualAmt >= 0) {
        if (item.debit <= item.actualAmt) {
          if (item.debit.charAt(0) === '.') {
            this.addProvData[index].debit = '0.';
            return;
          }
          const regex = /^\d+(\.\d{0,2})?$/;
          if (!regex.test(item.debit)) {
            alert('Only two decimal places are allowed.');
            const [integerPart, decimalPart] = item.debit.split('.');
            let truncatedDecimal = decimalPart ? `${integerPart}.${decimalPart.slice(0, 2)}` : integerPart;
            truncatedDecimal = parseFloat(truncatedDecimal).toFixed(2);
            this.addProvData[index].debit = truncatedDecimal;
          }
        } else {
          item.debit = null;
          alert('Debit is not greater than Amount.')
        }
      } else {
        item.debit = null;
        alert('You cannot enter a debit amount because the actual amount is less than zero.')
      }}
    },
    // qtyTwoDecimalValue(debit, index) {
    //   if (debit) {
    //     if (debit.charAt(0) === '.') {
    //       this.addProvData[index].debit = '0.';
    //     } else {
    //       var regex = /^\d+(\.\d{0,2})?$/g;
    //       if (!regex.test(debit)) {
    //         alert('Only two decimal places are allowed.');
    //         const [integerPart, decimalPart] = debit.split('.');
    //         const truncatedDecimal = parseFloat(
    //           `${integerPart}.${decimalPart.slice(0, 2)}`
    //         );
    //         setTimeout(() => {
    //           this.addProvData[index].debit = truncatedDecimal;
    //         }, 0);
    //       }
    //     }
    //   }
    // },
    highLightMandatoryFields() {
      for (let i of this.addProvData) {
        if (!i.po_type) {
          i.poTypeFlag = true;
        } else {
          i.poTypeFlag = false;
        }
      }
      const fieldsByType = {
        'With PO': [
          { key: 'vendor_name', flag: 'vendorFlag' },
          { key: 'po_type', flag: 'poTypeFlag' },
          { key: 'state_code', flag: 'stateFlag' },
          { key: 'cost_center', flag: 'costCenterFlag' },
          { key: 'account_num', flag: 'accNumFlag' },
          { key: 'sub_account_num', flag: 'subAccFlag' },
          { key: 'lob', flag: 'lobFlag' },
          { key: 'company', flag: 'companyFlag' },
          { key: 'department', flag: 'departmentFlag' },
          { key: 'prov_acc_num', flag: 'provAccFlag' },
          { key: 'po_number', flag: 'poNumFlag' },
          { key: 'debit', flag: 'debitFlag' },
          { key: 'journal_name', flag: 'journalNameFlag' },
          { key: 'item_description', flag: 'itemDescFlag' },
          { key: 'service_user', flag: 'serviceUserFlag' },
          { key: 'user_department', flag: 'userDepartmentFlag' }
        ],
        'Without PO': [
          { key: 'vendor_name', flag: 'vendorFlag' },
          { key: 'po_type', flag: 'poTypeFlag' },
          { key: 'state_code', flag: 'stateFlag' },
          { key: 'cost_center', flag: 'costCenterFlag' },
          { key: 'account_num', flag: 'accNumFlag' },
          { key: 'sub_account_num', flag: 'subAccFlag' },
          { key: 'lob', flag: 'lobFlag' },
          { key: 'company', flag: 'companyFlag' },
          { key: 'department', flag: 'departmentFlag' },
          { key: 'prov_acc_num', flag: 'provAccFlag' },
          { key: 'debit', flag: 'debitFlag' },
          { key: 'journal_name', flag: 'journalNameFlag' },
          { key: 'item_description', flag: 'itemDescFlag' },
          { key: 'service_user', flag: 'serviceUserFlag' },
          { key: 'user_department', flag: 'userDepartmentFlag' }
        ],
        'Without Vendor': [
          { key: 'service_date', flag: 'serviceDateFlag' },
          { key: 'po_type', flag: 'poTypeFlag' },
          { key: 'state_code', flag: 'stateFlag' },
          { key: 'cost_center', flag: 'costCenterFlag' },
          { key: 'account_num', flag: 'accNumFlag' },
          { key: 'sub_account_num', flag: 'subAccFlag' },
          { key: 'lob', flag: 'lobFlag' },
          { key: 'company', flag: 'companyFlag' },
          { key: 'department', flag: 'departmentFlag' },
          { key: 'prov_acc_num', flag: 'provAccFlag' },
          { key: 'debit', flag: 'debitFlag' },
          { key: 'journal_name', flag: 'journalNameFlag' },
          { key: 'item_description', flag: 'itemDescFlag' },
          { key: 'service_user', flag: 'serviceUserFlag' },
          { key: 'user_department', flag: 'userDepartmentFlag' }
        ]

      };
      for (let item of this.addProvData) {
        const fields = fieldsByType[item.po_type];
        if (fields) {
          fields.forEach(field => {
            item[field.flag] = !item[field.key];
          });
        }
      }
      this.addProvData = JSON.parse(JSON.stringify(this.addProvData));
    },
    addEditProvisionDetails() {
      this.highLightMandatoryFields();
      if (this.legalEntity.text && this.period && this.department.text && this.rowCount) {
        const filterData = this.addProvData.filter(ele => {
          // const isDebitOrCreditMissing = ele.debit || ele.credit;
          if (ele.po_type === 'Without Vendor') {
            return !ele.po_type
              || !ele.state_code
              || !ele.cost_center
              || !ele.account_num
              || !ele.sub_account_num.toString()
              || !ele.lob
              || !ele.company
              || !ele.department
              || !ele.prov_acc_num
              || !ele.service_date
              || !ele.debit
              || !ele.journal_name
            // || !isDebitOrCreditMissing
          } else {
            return !ele.po_type
              || !ele.state_code
              || !ele.cost_center
              || !ele.account_num
              || !ele.sub_account_num.toString()
              || !ele.lob
              || !ele.company
              || !ele.department
              || !ele.prov_acc_num
              || !ele.vendor_name
              || !ele.debit
              || !ele.journal_name
            // || !isDebitOrCreditMissing
          }
        });
        if (filterData.length) {
          alert('Kindly Fill Details Mandatory Fields.')
        } else {
          const poTypeDetails = this.addProvData.map(elem => {
            return {
              prov_dtl_id: elem.prov_dtl_id ? elem.prov_dtl_id : 0,
              po_type: elem.po_type,
              po_distribution_id: elem.po_distribution_id,
              code_combination_id: elem.code_combination_id,
              // code_combination_segment: elem.code_combination_segment,
              concatenated_segments: elem.concatenated_segments,
              state_code: elem.state_code,
              cost_center: elem.cost_center,
              account_num: elem.account_num,
              sub_account_num: elem.sub_account_num,
              service_date: elem.service_date,
              lob: elem.lob,
              company: elem.company,
              department: elem.department,
              account_desc: elem.account_desc,
              amount: elem.amount,
              vendor_id: elem.vendor_id,
              vendor_code: elem.vendor_code,
              vendor_name: elem.vendor_name,
              po_number: elem.po_number,
              po_currency: elem.po_currency,
              po_line_number: elem.po_line_num,
              release_num: elem.release_num,
              actual_amount: elem.actualAmt,
              item_description: elem.item_description,
              service_user: elem.service_user,
              user_department: elem.user_department,
              exchange_rate: elem.exchange_rate,
              working_days: elem.working_days,
              extra_days: elem.extra_days,
              leave_encashment: elem.leave_encashment,
              accounting_date: elem.accounting_date,
              journal_name: elem.journal_name,
              journal_description: elem.journal_description,
              debit: elem.debit,
              // credit: elem.credit,
              prov_acc_num: elem.prov_acc_num,
              expense_month: elem.expense_month,
              mis_4: elem.mis_4,
              mis_5: elem.mis_5
            };
          });
          const payload = {
            prov_hdr_id: this.provHdrId,
            erp_org_id: this.legalEntity.value,
            le_name: this.legalEntity.text,
            currency: this.currency,
            period: this.period,
            batch_name: this.batchName,
            approval_status: this.approvalStatus,
            line_count: this.rowCount,
            department: this.department.value,
            po_type_details: poTypeDetails ? poTypeDetails : null
          };
          this.loader = true;
          this.$store
            .dispatch('provisionsAdmin/addEditProvisionDetails', payload)
            .then(response => {
              this.loader = false;
              this.showAlert = true;
              if (response.status === 200) {
                // this.arrayLengthFlag = 
                this.editMode = false;
                this.provHdrId = response.data.data;
                this.getProvisonHdrById();
                // this.getProvisonDtlById();
                this.isSuccess = true;
                this.responseMsg = response.data.message;
                this.$emit('updateProvision');
              } else {
                this.isSuccess = false;
                this.responseMsg = response.response.data.message;
              }
            })
            .catch(() => {
              this.loader = false;
              this.isSuccess = false;
              this.responseMsg = appStrings.autoFailedMsg;
            });
        }
      }
      else {
        alert('Please Fill Header Mandatory Fields.')
      }
    },
    // Functions for PO Number automatic fill------------------->
    // selectedPoNum(item) {
    //   for (let i = 0; i < this.addProvData.length; i++) {
    //     if (this.addProvData[i].po_type === 'With PO') {
    //       this.addProvData[i].po_number = item[i].po_number;
    //       this.addProvData[i].po_currency = item[i].po_currency;
    //       this.addProvData[i].po_line_num = item[i].line_num;
    //       this.addProvData[i].item_description = item[i].item_description;
    //       this.addProvData[i].release_num = item[i].release_num;
    //       this.addProvData[i].amount = item[i].amount;
    //       this.addProvData[i].concatenated_segments = this.truncateString(item[i].CONCATENATED_SEGMENTS);
    //       this.addProvData[i].account_desc = item[i].acc_desc;
    //       this.splitCodeCombWithPo(this.addProvData[i].concatenated_segments, i);
    //       this.getRealWithPoAmount(i);
    //     }
    //   }
    //   this.showCommonModal = false;
    // },
    // splitCodeCombWithPo(data, i) {
    //   const accSegment = data;
    //   const splitSegment = accSegment.split('.');
    //   this.addProvData[i].lob = splitSegment[1];
    //   this.addProvData[i].company = splitSegment[0];
    //   this.addProvData[i].department = splitSegment[2];
    //   this.addProvData[i].state_code = splitSegment[3];
    //   this.addProvData[i].cost_center = splitSegment[4];
    //   this.addProvData[i].account_num = splitSegment[5];
    //   this.addProvData[i].sub_account_num = splitSegment[6];
    //   this.addProvData[i].po_distribution_id = null;
    //   this.addProvData[i].concatSeg_flag = true;
    // },
    // getRealWithPoAmount(index) {
    //   const payload = {
    //     po_num: this.addProvData[index].po_number,
    //     po_line_num: this.addProvData[index].po_line_num,
    //     concatseg: this.addProvData[index].concatenated_segments
    //   };
    //   this.loader = true;
    //   this.$store
    //     .dispatch('provisionsAdmin/getRealAmount', payload)
    //     .then(resp => {
    //       this.loader = false;
    //       if (resp.status === 200) {
    //         this.realAmt = resp.data;
    //         this.actualAmt = this.addProvData[index].actualAmt - this.realAmt;
    //         this.addProvData[index].actualAmt = this.actualAmt;
    //         // this.addProvData = JSON.parse(JSON.stringify(this.addProvData));
    //       }
    //     })
    //     .catch(() => {
    //       this.loader = false;
    //     });
    // },
    mapSelectedVsetData(item) {
      if (this.modalType === 'legal_entity') {
        this.legalEntity = {
          text: item.le_name,
          value: item.le_id
        };
        if (this.legalEntity.value) {
          this.getCurrencyDetails();
        }
      } else if (this.modalType === 'period') {
        this.period = item.period_name;
      } else if (this.modalType === 'expenseMonth') {
        this.addProvData[this.specIndex].expense_month = item.period_name;
      }
      else if (this.modalType === 'vendor') {
        this.addProvData[this.specIndex].vendor_id = item.vendor_id;
        this.addProvData[this.specIndex].vendor_code = item.vendor_code;
        this.addProvData[this.specIndex].vendor_name = item.vendor_name;
      } else if (this.modalType === 'po_number') {
        // this.addProvData[this.specIndex].vendor_name = item.po_number;
        // this.addProvData[this.specIndex].vendor_code = item.po_number;
        // this.addProvData[this.specIndex].vendor_id = item.po_number;
        this.addProvData[this.specIndex].po_number = item.po_number;
        this.addProvData[this.specIndex].po_currency = item.po_currency;
        this.addProvData[this.specIndex].po_line_num = item.line_num;
        this.addProvData[this.specIndex].item_description = item.item_description;
        this.addProvData[this.specIndex].release_num = item.release_num;
        this.addProvData[this.specIndex].amount = item.amount;
        this.addProvData[this.specIndex].concatenated_segments = this.truncateString(item.CONCATENATED_SEGMENTS);
        // this.addProvData[this.specIndex].company = null;
        // this.addProvData[this.specIndex].lob = null;
        // this.addProvData[this.specIndex].department = null;
        // this.addProvData[this.specIndex].state_code = null;
        // this.addProvData[this.specIndex].cost_center = null;
        // this.addProvData[this.specIndex].account_num = null;
        this.addProvData[this.specIndex].account_desc = item.acc_desc;
        // this.addProvData[this.specIndex].sub_account_num = null;
        this.segmentIndex = this.specIndex;
        this.splitCodeComb(this.addProvData[this.segmentIndex].concatenated_segments);
        this.getRealAmount();
        // this.getAccCodeDetails()
      } else if (this.modalType === 'account_code') {
        this.addProvData[this.specIndex].po_distribution_id =
          item.po_distribution_id;
        this.addProvData[this.specIndex].code_combination_id =
          item.code_combination_id;
        this.addProvData[this.specIndex].code_combination_segment =
          item.code_combination_segment;
        this.addProvData[this.specIndex].concatenated_segments =
          item.concatenated_segments;
        this.splitCodeComb(this.addProvData[this.specIndex].concatenated_segments);
      } else if (this.modalType === 'state_code') {
        this.addProvData[this.specIndex].state_code = item.state_code;
        this.addProvData[this.specIndex].cost_center = null;
      } else if (this.modalType === 'cost_center') {
        this.addProvData[this.specIndex].cost_center = item.cost_center;
      } else if (this.modalType === 'account_num') {
        this.addProvData[this.specIndex].account_num = item.account_num;
        this.addProvData[this.specIndex].account_desc = item.account_desc;
        this.addProvData[this.specIndex].sub_account_num = null;
        this.getProvisionAccountMappingData(this.addProvData[this.specIndex].account_num, this.specIndex);
      } else if (this.modalType === 'sub_account_num') {
        this.addProvData[this.specIndex].sub_account_num = item.sub_account_num;
        this.segmentIndex = this.specIndex;
        this.newFunction();
      } else if (this.modalType === 'provision_account') {
        this.addProvData[this.specIndex].prov_acc_num = item.prov_acc_num;
      }
      this.addProvData = JSON.parse(
        JSON.stringify(this.addProvData)
      );
      this.showHideCommonModal(false);
    },
    truncateString(input) {
      // let [beforeHash, afterHash] = input.split('#');
      // let afterHashParts = afterHash.split('.');
      // let truncatedAfterHash = afterHashParts.slice(0, 3).join('.');
      // return `${beforeHash}#${truncatedAfterHash}`;
      let parts = input.split('.');
      return parts.slice(0, 7).join('.');
    },
    splitCodeComb(data) {
      const accSegment = data;
      const splitSegment = accSegment.split('.');
      this.addProvData[this.segmentIndex].company = splitSegment[0];
      this.addProvData[this.segmentIndex].lob = splitSegment[1];
      this.addProvData[this.segmentIndex].department = splitSegment[2];
      this.addProvData[this.segmentIndex].state_code = splitSegment[3];
      this.addProvData[this.segmentIndex].cost_center = splitSegment[4];
      this.addProvData[this.segmentIndex].account_num = splitSegment[5];
      this.addProvData[this.segmentIndex].sub_account_num = splitSegment[6];
      this.addProvData[this.segmentIndex].po_distribution_id = null;
      this.addProvData[this.segmentIndex].concatSeg_flag = true;
      // this.getAccNoDetails(result[0].po_distribution_id);
    },
    getRealAmount() {
      const payload = {
        po_num: this.addProvData[this.specIndex].po_number,
        po_line_num: this.addProvData[this.specIndex].po_line_num,
        concatseg: this.addProvData[this.specIndex].concatenated_segments
      };
      this.loader = true;
      this.$store
        .dispatch('provisionsAdmin/getRealAmount', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.realAmt = resp.data;
            this.actualAmt = this.addProvData[this.segmentIndex].amount - this.realAmt;
            this.addProvData[this.segmentIndex].actualAmt = this.actualAmt;
            this.addProvData = JSON.parse(JSON.stringify(this.addProvData));
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    newFunction() {
      if (this.addProvData[this.segmentIndex].state_code && this.addProvData[this.segmentIndex].cost_center && this.addProvData[this.segmentIndex].account_num && this.addProvData[this.segmentIndex].sub_account_num.toString()) {
        this.getLobCompanyDepartmentDetails();
      }
    },
    getCurrencyDetails() {
      const payload = {
        legal_entity_id: this.legalEntity.value
      };
      this.loader = true;
      this.$store
        .dispatch('provisionsAdmin/getCurrencyDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.CURRENCY_CODE;
            this.currency = result;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    removeRow(index) {
      if (this.editMode) {
        this.addProvData.splice(index, 1);
      }
    },
    linesCount(count) {
      if (!count || count == 0) {
        this.addProvData = [];
      } else {
        // if(this.addProvData.length>0) {

        // }
        // this.addProvData = [];
        for (let i = 0; i < count; i++) {
          this.addProvData.push({
            prov_hdr_id: 0,
            po_type: null,
            po_distribution_id: null,
            code_combination_id: null,
            code_combination_segment: null,
            concatenated_segments: null,
            service_date: null,
            company: null,
            lob: null,
            department: null,
            state_code: null,
            cost_center: null,
            account_num: null,
            sub_account_num: null,
            account_desc: null,
            amount: null,
            vendor_id: null,
            vendor_code: null,
            vendor_name: null,
            po_number: null,
            po_currency: null,
            po_line_num: null,
            item_description: null,
            release_num: null,
            service_user: null,
            user_department: null,
            exchange_rate: null,
            working_days: null,
            extra_days: null,
            leave_encashment: null,
            mis_4: null,
            mis_5: null,
            journal_name: null,
            journal_description: null,
            debit: null,
            credit: null,
            prov_acc_num: null,
            expense_month: null,
          });
        }
      }

      // this.rowCount = null;
    },
    addNewRow() {
      if (this.editMode) {
        this.addProvData.push({
          prov_hdr_id: 0,
          po_type: null,
          po_distribution_id: null,
          code_combination_id: null,
          code_combination_segment: null,
          concatenated_segments: null,
          service_date: null,
          company: null,
          lob: null,
          department: null,
          state_code: null,
          cost_center: null,
          account_num: null,
          sub_account_num: null,
          prov_acc_num: null,
          account_desc: null,
          amount: null,
          vendor_id: null,
          vendor_code: null,
          vendor_name: null,
          po_number: null,
          po_currency: null,
          po_line_num: null,
          release_num: null,
          item_description: null,
          service_user: null,
          user_department: null,
          exchange_rate: null,
          working_days: null,
          extra_days: null,
          leave_encashment: null,
          mis_4: null,
          mis_5: null,
          journal_name: null,
          journal_description: null,
        });
      }
    },
    setBatchName(batchName) {
      this.addProvData.map(ele => { ele.journal_name = batchName; ele.journal_description = batchName; return ele; })
    },
    showHideCommonModal(flag, type, index) {
      this.modalType = type;
      this.specIndex = index;
      if (flag) {
        if (this.modalType === 'po_number') {
          this.vendorCode = this.addProvData[this.specIndex].vendor_code;
          this.legalEntityValue = this.legalEntity.value;
        } else if (this.modalType === 'account_code') {
          this.poNumber = this.addProvData[this.specIndex].po_number;
          this.poLineNum = this.addProvData[this.specIndex].po_line_num;
        } else if (this.modalType === 'expenseMonth') {
          this.expenseMonth = this.addProvData[this.specIndex].expense_month;
        } else if (this.modalType === 'cost_center') {
          this.stateCode = this.addProvData[this.specIndex].state_code;
          this.legalEntityValue = this.legalEntity.value;
        } else if (this.modalType === 'sub_account_num') {
          this.accountNum = this.addProvData[this.specIndex].account_num;
        }
      }
      if (this.modalType === 'cost_center' && !this.stateCode) {
        this.showCommonModal = false;
        alert('Please Select State.')
      } else if (this.modalType === 'po_number' && !this.legalEntity.text) {
        this.showCommonModal = false;
        alert('Please Select Legal Entity.')
      } else if (this.modalType === 'sub_account_num' && !this.accountNum) {
        this.showCommonModal = false;
        alert('Please Select Account No.')
      } else {
        this.showCommonModal = flag;
      }
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.DEPARTMENT) {
        this.department = {
          value: item.value_code,
          text: item.value_meaning
        };
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode, index) {
      switch (vsetCode) {
        case 'legal_entity':
          this.legalEntity = this.defaultValue;
          this.currency = null;
          break;
        case 'period':
          this.period = null;
          break;
        case appStrings.VALUESETTYPE.DEPARTMENT:
          this.department = this.defaultValue;
          break;
        case 'vendor':
          this.addProvData[index].vendor_id = null;
          this.addProvData[index].vendor_code = null;
          this.addProvData[index].vendor_name = null;
          this.addProvData[index].po_number = null;
          this.addProvData[index].po_currency = null;
          this.addProvData[index].po_line_num = null;
          this.addProvData[index].release_num = null;
          this.addProvData[index].item_description = null;
          this.addProvData[index].state_code = null;
          this.addProvData[index].cost_center = null;
          this.addProvData[index].company = null;
          this.addProvData[index].department = null;
          this.addProvData[index].lob = null;
          this.addProvData[index].account_num = null;
          // this.addProvData[index].account_desc = null;
          this.addProvData[index].sub_account_num = null;
          break;
        case 'po_number':
          this.addProvData[index].po_number = null;
          this.addProvData[index].po_currency = null;
          this.addProvData[index].po_line_num = null;
          this.addProvData[index].release_num = null;
          this.addProvData[index].item_description = null;
          this.addProvData[index].po_distribution_id = null;
          this.addProvData[index].state_code = null;
          this.addProvData[index].cost_center = null;
          this.addProvData[index].company = null;
          this.addProvData[index].department = null;
          this.addProvData[index].lob = null;
          this.addProvData[index].account_num = null;
          this.addProvData[index].account_desc = null;
          this.addProvData[index].sub_account_num = null;
          this.addProvData[index].actualAmt = null;
          break;
        // case 'account_code':
        //   this.addProvData[index].po_distribution_id = null;
        //   this.addProvData[index].code_combination_id = null;
        //   this.addProvData[index].code_combination_segment = null;
        //   this.addProvData[index].concatenated_segments = null;
        //   break;
        case 'state_code':
          this.addProvData[index].state_code = null;
          this.addProvData[index].cost_center = null;
          this.addProvData[index].company = null;
          this.addProvData[index].department = null;
          this.addProvData[index].lob = null;
          break;
        case 'cost_center':
          this.addProvData[index].cost_center = null;
          this.addProvData[index].company = null;
          this.addProvData[index].department = null;
          this.addProvData[index].lob = null;
          break;
        case 'account_num':
          this.addProvData[index].account_num = null;
          this.addProvData[index].account_desc = null;
          this.addProvData[index].company = null;
          this.addProvData[index].department = null;
          this.addProvData[index].lob = null;
          break;
        case 'sub_account_num':
          this.addProvData[index].sub_account_num = null;
          this.addProvData[index].company = null;
          this.addProvData[index].department = null;
          this.addProvData[index].lob = null;
          break;
        case 'prov_acc_num':
          this.addProvData[index].prov_acc_num = null;
          break;
      }
    },
    changePoType(item, index) {
      if (item) {
        this.addProvData[index].po_distribution_id = null;
        this.addProvData[index].code_combination_id = null;
        this.addProvData[index].code_combination_segment = null;
        this.addProvData[index].concatenated_segments = null;
        this.addProvData[index].state_code = null;
        this.addProvData[index].cost_center = null;
        this.addProvData[index].account_num = null;
        this.addProvData[index].account_desc = null;
        this.addProvData[index].service_date = null;
        this.addProvData[index].sub_account_num = null;
        this.addProvData[index].prov_acc_num = null;
        this.addProvData[index].amount = null;
        this.addProvData[index].vendor_id = null;
        this.addProvData[index].vendor_code = null;
        this.addProvData[index].vendor_name = null;
        this.addProvData[index].po_number = null;
        this.addProvData[index].po_currency = null;
        this.addProvData[index].po_line_num = null;
        this.addProvData[index].release_num = null;
        this.addProvData[index].item_description = null;
        this.addProvData[index].service_user = null;
        this.addProvData[index].user_department = null;
        this.addProvData[index].exchange_rate = null;
        this.addProvData[index].working_days = null;
        this.addProvData[index].extra_days = null;
        this.addProvData[index].leave_encashment = null;
        this.addProvData[index].mis_4 = null;
        this.addProvData[index].mis_5 = null;
        this.addProvData[index].company = null;
        this.addProvData[index].lob = null;
        this.addProvData[index].department = null;
        this.addProvData[index].debit = null;
        this.addProvData[index].actualAmt = null;
      }
    },
    showHideApproveRejectModal(flag, type) {
      this.showApproveRejectModal = flag;
      this.approvRejectType = type;
    },
    approveRejectProvisons(status) {
      const payload = {
        event_id: this.provRowDetails ? this.provRowDetails.wf_event_id : this.wfProvisionDetails ? this.wfProvisionDetails.wf_event_id : null,
        wf_inprocess_dtl_id: this.provRowDetails ? this.provRowDetails.wf_inprocess_dtl_id : this.wfProvisionDetails ? this.wfProvisionDetails.wf_inprocess_dtl_id : null,
        notification_status: status,
        notification_comments:
          status === 'REJECTED' ? this.rejectedReason : this.approvalReason
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/approvalRejectSingle', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            // this.getVendorInvoiceDetailsById();
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            if (status === 'REJECTED') {
              this.showHideApproveRejectModal(false);
            } else if (status === 'APPROVED') {
              this.showHideApproveRejectModal(false);
            }
            this.getProvisonHdrById();
            // this.$emit('updateInvoice');
          } else {
            this.showAlert = false;
            alert(response.response.data.message);
            // this.isSuccess = false;
            // this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getApprovedBatch() {
      const payload = {
        prov_hdr_id: this.provRowDetails ? this.provHdrId : this.wfProvisionDetails ? this.provHdrId : null,
      }
      this.loader = true;
      this.$store
        .dispatch('provisionsAdmin/getApprovedBatch', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.ouName = resp.data.data.ou_name;
            this.requestName = resp.data.data.request_name;
            this.respName = resp.data.data.resp_name;
            this.leIdRequest1 = resp.data.data.argument1;
            this.pathRequest2 = resp.data.data.argument2;
            this.hdrIdRequest3 = resp.data.data.argument3;
            this.successMsg = resp.data.data.success_msg;
            this.provisionSubmitRequest();
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    provisionSubmitRequest() {
      const payload = {
        InputParameters: [
          {
            REQUEST_NAME: this.requestName,
            OU_NAME: this.ouName,
            USER_NAME: "",
            RESP_NAME: this.respName,
            ARGUMENT1: this.leIdRequest1,
            ARGUMENT2: this.pathRequest2,
            ARGUMENT3: this.hdrIdRequest3,
            ARGUMENT4: "",
            ARGUMENT5: "",
            ARGUMENT6: "",
            ARGUMENT7: "",
            ARGUMENT8: "",
            ARGUMENT9: "",
            ARGUMENT10: "",
            ARGUMENT11: "",
            ARGUMENT12: "",
            ARGUMENT13: "",
            ARGUMENT14: "",
            ARGUMENT15: ""
          }
        ]
      };
      this.loader = true;
      this.$store
        .dispatch('provisionsAdmin/provisionSubmitRequest', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.showAlert = false;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getAccCodeDetails() {
      const payload = {
        _page: 0,
        _limit: 100,
        po_num: this.addProvData[this.specIndex].po_number,
        po_line_num: this.addProvData[this.specIndex].po_line_num,
        concatseg: ''
      };
      this.loader = true;
      this.$store
        .dispatch('provisionsAdmin/getAccCodeDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            if (result.length > 1) {
              this.addProvData[this.segmentIndex].company = null;
              this.addProvData[this.segmentIndex].lob = null;
              this.addProvData[this.segmentIndex].department = null;
              this.addProvData[this.segmentIndex].state_code = null;
              this.addProvData[this.segmentIndex].cost_center = null;
              this.addProvData[this.segmentIndex].account_num = null;
              this.addProvData[this.segmentIndex].sub_account_num = null;
              this.addProvData[this.segmentIndex].concatSeg_flag = true;
              this.addProvData[this.segmentIndex].po_distribution_id = null;
              this.getAccNoDetails(result[0].po_distribution_id);
            } else if (result.length == 1) {
              const accSegment = result[0].concatenated_segments;
              const splitSegment = accSegment.split('.');
              this.addProvData[this.segmentIndex].company = splitSegment[0];
              this.addProvData[this.segmentIndex].lob = splitSegment[1];
              this.addProvData[this.segmentIndex].department = splitSegment[2];
              this.addProvData[this.segmentIndex].state_code = splitSegment[3];
              this.addProvData[this.segmentIndex].cost_center = splitSegment[4];
              this.addProvData[this.segmentIndex].account_num = splitSegment[5];
              this.addProvData[this.segmentIndex].sub_account_num = splitSegment[6];
              this.addProvData[this.segmentIndex].concatenated_segments = null;
              this.addProvData[this.segmentIndex].concatSeg_flag = false;
              this.addProvData[this.segmentIndex].po_distribution_id = null;
              this.getAccNoDetails(result[0].po_distribution_id);
            }
          }
          this.addProvData = JSON.parse(JSON.stringify(this.addProvData))

        })
        .catch(() => {
          this.loader = false;
        });
    },
    getAccNoDetails(poDistributionId) {
      const payload = {
        _page: 0,
        _limit: 100,
        account_num: this.addProvData[this.segmentIndex].account_num,
      };
      this.loader = true;
      this.$store
        .dispatch('provisionsAdmin/getAccNoDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            const isIdPresent = this.addProvData.some(obj => obj.po_distribution_id == poDistributionId);
            if (!isIdPresent) {
              this.addProvData[this.segmentIndex].po_distribution_id = poDistributionId;
              this.addProvData[this.segmentIndex].account_desc = result[0].account_desc;
            } else {
              alert('Distribution id already selected');
              this.addProvData[this.segmentIndex].po_number = null,
                this.addProvData[this.segmentIndex].po_currency = null,
                this.addProvData[this.segmentIndex].po_line_num = null,
                this.addProvData[this.segmentIndex].release_num = null,
                this.addProvData[this.segmentIndex].item_description = null,
                this.addProvData[this.segmentIndex].account_num = null;
              this.addProvData[this.segmentIndex].sub_account_num = null;
              this.addProvData[this.segmentIndex].account_desc = null;
              this.addProvData[this.segmentIndex].company = null,
                this.addProvData[this.segmentIndex].lob = null,
                this.addProvData[this.segmentIndex].department = null,
                this.addProvData[this.segmentIndex].state = null,
                this.addProvData[this.segmentIndex].cost_centre = null
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getLobCompanyDepartmentDetails() {
      const payload = {
        le_id: this.legalEntity.value,
        state_code: this.addProvData[this.segmentIndex].state_code,
        cost_center: this.addProvData[this.segmentIndex].cost_center,
        // account_number: this.addProvData[this.segmentIndex].account_num,
        // subaccount_number: this.addProvData[this.segmentIndex].sub_account_num
      };
      this.loader = true;
      this.$store
        .dispatch('provisionsAdmin/getLobCompanyDepartmentDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            this.addProvData[this.segmentIndex].company = result[0].company;
            this.addProvData[this.segmentIndex].lob = result[0].lob;
            this.addProvData[this.segmentIndex].department = result[0].department;
          }
          this.addProvData = JSON.parse(JSON.stringify(this.addProvData))
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getProvisionAccountMappingData(accNum, index) {
      const payload = {
        account_num: accNum,
      };
      this.loader = true;
      this.$store
        .dispatch('provisionsAdmin/getProvisionAccountMappingData', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.page;
            this.addProvData[index].prov_acc_num = result[0].prov_acc_num;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};